jQuery(document).ready(function($){



    $('.slider-photo, .slider-reviews').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
    });

    $('.slider-logo').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });


});
